<template>
	<div class="row-components">
		<div class="card-component">
			<div class="image-card-left"></div>
			Curated list of tools, services, image and illustration stocks.
			<div class="url-text">
				Click to see more
			</div>
		</div>
		<div class="spacer"></div>
		<div class="card-component" :class="clicked">
			<div class="image-card-right"></div>
			crydle.ml – funny game with images, generated by not so powerful ai
			<div class="url-text">
				Read more soon
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return{

			}
		}
	}
</script>

<style scoped>
@media screen and (min-width: 768px){
	.row-components{
		align-self: center;
		display: flex;
		margin-top: 128px;
		margin-bottom: 128px;
		border-radius: 36px;
	}
	.card-component{
		cursor: pointer;
		background-color: rgba(241,241,241,0.3);
		width: 312px;
		padding: 24px;
		height: fit-content;
		border: 1px solid #F6F3F4;
		border-radius: 36px;
		box-shadow: 0px 4px 16px rgba(186, 186, 186, 0.24);
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		color: rgba(51, 51, 51, 1);
		transition: background-color 0.2s ease;
	}
	.card-component:active{
		background-color: rgba(229, 229, 229, 1);
		box-shadow: 0px 4px 16px rgba(186, 186, 186, 0.24);
	}
	.card-component:active .url-text{
		color: rgba(51, 51, 51, 0.2)
	}
	.card-component .url-text {
		transition: color 0.3s ease; /* Плавное изменение цвета текста */
	}
	.card-component:hover .url-text {
		color: rgba(51, 51, 51, 0.57); /* Желаемый цвет при наведении */
	}
	.card-component:hover {
		box-shadow: 0px 4px 16px rgba(186, 186, 186, 0.24),
		0px 8px 32px rgba(186, 186, 186, 0.24); /* Желаемый цвет при наведении */
	}
	.spacer{
		width: 16px;
	}
	.image-card-left{
		width: 80px;
		height: 80px;
		background-color: rgba(255, 255, 255, 0.5);
		border-radius: 16px;
		background-image: url(~@/assets/left.png);
		background-size: cover;
		background-position: center;
		margin-bottom: 16px;

	}
	.image-card-right{
		width: 80px;
		height: 80px;
		background-color: transparent;
		border-radius: 16px;
		background-image: url(~@/assets/right.png);
		background-size: cover;
		background-position: center;
		margin-bottom: 16px;
	}
	.url-text{
		margin-top: 16px;
		font-size: 17px;
		font-weight: 600;
		color: rgba(51, 51, 51, 0.2)
	}
}
@media screen and (max-width: 768px){
	.row-components{	
		margin-left: auto;
		margin-right: auto;	
		margin-top: 64px;
		display: flex;
		flex-direction: column;
		border-radius: 16px;	
		width: calc(100% - 32px);
	}
	.card-component{
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;
		background-image: none;
		background-color: rgba(241,241,241,0.3);
		box-shadow: 0px 8px 32px rgba(186, 186, 186, 0.18);
		width: calc(100% - 48px);
		padding: 24px;
		height: fit-content;
		border: 1px solid #F6F3F4;
		border-radius: 16px;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		color: rgba(51, 51, 51, 1);
	}
	.card-component:active{
		background-color: rgba(229, 229, 229, 1);
		box-shadow: 0px 4px 16px rgba(186, 186, 186, 0.24);
		outline: none;
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}
	.card-component:active .url-text{
		color: rgba(51, 51, 51, 0.2)
	}
	.card-component .url-text {
		transition: color 0.3s ease; /* Плавное изменение цвета текста */
	}
	.image-card-left{
		width: 80px;
		height: 80px;
		background-color: rgba(255, 255, 255, 0.5);
		border-radius: 16px;
		background-image: url(~@/assets/left.png);
		background-size: cover;
		background-position: center;
		margin-bottom: 16px;

	}
	.image-card-right{
		width: 80px;
		height: 80px;
		background-color: transparent;
		border-radius: 16px;
		background-image: url(~@/assets/right.png);
		background-size: cover;
		background-position: center;
		margin-bottom: 16px;
	}
	.spacer{
		height: 16px;
	}
	.url-text{
		margin-top: 16px;
		font-size: 17px;
		font-weight: 600;
		color: rgba(51, 51, 51, 0.2);

	}
}
</style>